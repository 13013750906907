<template>
  <div class="b-frontoffice fill-height d-flex flex-column justify-space-between">
    <the-header />
    <router-view class="grow" />
    <the-alert />
    <the-footer />
  </div>
</template>
<script>
import TheHeader from '../TheHeader'
import TheFooter from '../TheFooter'
import TheAlert from '../TheAlert'

/**
 * Component that represent the layout of the backoffice application.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 9:55 a. m.
 */
export default {
  name: 'TheLayoutBackoffice',
  components: {
    TheHeader,
    TheFooter,
    TheAlert
  },
  data () {
    return {}
  }
}
</script>
