<template>
  <v-snackbar
    v-model="showAlertMessage"
    :color="getAlertObject.type"
    :timeout="0"
  >
    <v-layout class="row no-gutters align-center">
      <v-flex class="col grow">
        {{ getAlertObject.text }}
      </v-flex>
      <v-flex class="col shrink">
        <v-btn
          id="btn-alert-close"
          color="grey darken-2"
          text
          @click="hideAlert"
        >
          {{ $t('btn_close') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-snackbar>
</template>
<script>
import { HIDE_ALERT } from '../../store/mutations-types'
import { mapGetters } from 'vuex'

/**
 * Component to show message in the alert to user
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 20/12/2019 12:58 a. m.
 */
export default {
  name: 'TheAlert',
  data () {
    return {
      showAlert: true
    }
  },
  computed: {
    ...mapGetters([
      'getAlertObject'
    ]),
    /**
     * Variable to show or hide the alert.
     *
     * @author C Camilo Castellanos
     * @version 1.0.0
     * @since 20/12/2019 12:58 a. m.
     */
    showAlertMessage () {
      return this.$store.state.alert.show
    }
  },
  methods: {
    /**
     * Function to hide the alert
     *
     * @author C Camilo Castellanos
     * @version 1.0.0
     * @since 20/12/2019 12:59 a. m.
     */
    hideAlert () {
      this.$store.commit(HIDE_ALERT)
    }
  }
}
</script>
<style scoped></style>
<i18n>
  {
  "es": {
  "btn_close": "Cerrar"
  }
  }
</i18n>
